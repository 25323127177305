import styled from 'styled-components';

import { media } from '@pik/pik-ui';

export const LangWrapper = styled.div`
  .Link-active {
    pointer-events: none;
  }
  
 .Link { 
    font-size: 15px;
    font-family: Graphik;
    font-weight: 500;
    margin-right: 1px;
    
    &:first-child {
      margin-right: 16px;
    }
    
    @media ${media.max1024} {
      &:first-child {
        display: none;
      }
      &:last-child {
        margin: 0;
        opacity: 0.9;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  
  &.english-version .Link { 
    font-size: 15px;
    
    @media ${media.max1024} {
      &:first-child {
        display: block;
        margin: 0;
        opacity: 0.9;
        font-size: 18px;
        line-height: 24px;
      }
      &:last-child {
        display: none;
      }
    }
  }
`;
