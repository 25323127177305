module.exports = {
  '/': {
    name: 'Главная',
    nameEng: 'Main',

    links: {
      '/videos': {
        name: 'Видео',
        nameEng: 'Videos',
        withoutLinks: true,
      },
      '/cmd': {
        name: 'Регистрация на День Рынков Капитала (CMD) ПИК 2021',
        nameEng: 'PIK Capital Markets Day',
        withoutLinks: true,
      },
      '/agreement': {
        name: 'Согласие',
        nameEng: 'Agreement',
        withoutLinks: true,
      },

      '/fraud-check': {
        name: 'Противодействие мошенничеству',
        // nameEng: 'Fraud prevention',
        withoutLinks: true,
      },

      '/about': {
        name: 'О Компании',
        nameEng: 'About',
        redirect: '/about/info',

        links: {
          '/about/info': {
            name: 'Общая информация',
            nameEng: 'General information',
          },

          '/about/financial-performance': {
            name: 'Финансовые показатели',
            nameEng: 'Financial and operational data',
            redirect: '/about/financial-performance/main-indicators',

            links: {
              '/about/financial-performance/main-indicators': {
                name: 'Основные показатели',
                nameEng: 'Overview',
              },
              '/about/financial-performance/profit-and-loss': {
                name: 'Отчет о прибыли и убытках',
                nameEng: 'Profit and loss statement',
              },
              '/about/financial-performance/balance': {
                name: 'Баланс',
                nameEng: 'Balance sheet',
              },
              '/about/financial-performance/cash-flow-statement': {
                name: 'Отчет о движении денежных средств',
                nameEng: 'Cash flow statement',
              },
              '/about/financial-performance/valuation': {
                name: 'Оценка земельного банка',
                nameEng: 'Landbank valuation report',
              },
              '/about/financial-performance/factbook': {
                name: 'Исторические данные',
                nameEng: 'Factbook',
              },
            },
          },

          '/about/corporate-governance': {
            name: 'Корпоративное управление',
            nameEng: 'Corporate governance',
            redirect: '/about/corporate-governance/governance-structure',

            links: {
              '/about/corporate-governance/governance-structure': {
                name: 'Система управления',
                nameEng: 'Governance structure',
              },
              '/about/corporate-governance/shareholders-meeting': {
                name: 'Собрание акционеров',
                nameEng: 'Shareholders meeting',
              },
              // '/about/corporate-governance/board-of-directors': {
              //   name: 'Совет директоров',
              //   nameEng: 'Board of directors',
              // },
              // '/about/corporate-governance/committees-of-bod': {
              //   name: 'Комитеты совета директоров',
              //   nameEng: 'Committees of BoD',
              // },
              // '/about/corporate-governance/management-board': {
              //   name: 'Правление',
              //   nameEng: 'Management board',
              // },
              // '/about/corporate-governance/director': {
              //   name: 'Генеральный директор',
              //   nameEng: 'CEO',
              // },
              // временный костыль одной новости
              '/about/corporate-governance/news/18042022': {
                nameEng: 'News',
                withoutLinks: true,
              },
            },
          },

          '/about/sustainable-development': {
            name: 'Устойчивое развитие',
          },
        },
      },

      '/about/news-and-reports': {
        name: 'Новости и отчеты',
        nameEng: 'News and reports',
        redirect: '/about/news-and-reports/news',

        links: {
          '/about/news-and-reports/news': {
            name: 'Новости',
            nameEng: 'News',
            withoutLinks: true,

            links: {
              '/about/news-and-reports/news/:id': {},
            },
          },
          '/about/news-and-reports/investor-calendar': {
            name: 'Календарь инвестора',
            nameEng: 'Investor calendar ',
          },
          '/about/news-and-reports/reports': {
            name: 'Отчеты',
            nameEng: 'Reports',
            redirect: '/about/news-and-reports/reports/operational-results',

            links: {
              '/about/news-and-reports/reports/operational-results': {
                name: 'Операционные результаты',
                nameEng: 'Operational Results',
              },
              '/about/news-and-reports/reports/financial-results': {
                name: 'Финансовые результаты',
                nameEng: 'Financial Results',
              },
              '/about/news-and-reports/reports/annual-report': {
                name: 'Годовые отчеты',
                nameEng: 'Annual reports',
              },
              '/about/news-and-reports/reports/valuation-reports': {
                name: 'Отчеты об оценке',
                nameEng: 'Valuation reports',
              },
            },
          },
          '/about/news-and-reports/presentations': {
            name: 'Презентации',
            nameEng: 'Presentations',
          },
        },
      },

      '/about/share-capital': {
        name: 'Акционерный капитал',
        nameEng: 'Shares',
        redirect: '/about/share-capital/share-information',

        links: {
          '/about/share-capital/share-information': {
            name: 'Информация об акциях',
            nameEng: 'Share information',
          },
          // '/about/share-capital/shareholder-structure': {
          //   name: 'Структура акционеров',
          //   nameEng: 'Shareholder structure',
          //   withoutLinks: true,
          // },
          '/about/share-capital/dividends': {
            name: 'Дивиденды',
            nameEng: 'Dividends',
          },
          '/about/share-capital/equity-analysts': {
            name: 'Аналитики',
            nameEng: 'Analyst coverage',
          },
          '/about/share-capital/offering-memorandum': {
            name: 'Меморандум о предложении',
            nameEng: 'Offering Memorandum',
            withoutLinks: true,
          },
          '/about/share-capital/information-disclosure': {
            name: 'Раскрытие информации',
            nameEng: 'Regulatory Documents',
            redirect: '/about/share-capital/information-disclosure/normative-document',
            redirectEng: '/about/share-capital/information-disclosure/regulatory-documents',
            withoutLinksEng: true,

            links: {
              '/about/share-capital/information-disclosure/normative-document': {
                name: 'Нормативные документы',
              },
              '/about/share-capital/information-disclosure/annual-report': {
                name: 'Годовые отчеты',
              },
              '/about/share-capital/information-disclosure/financial-statements': {
                name: 'Бухгалтерская отчетность',
              },
              '/about/share-capital/information-disclosure/quarterly-reports': {
                name: 'Ежеквартальные отчеты',
              },
              '/about/share-capital/information-disclosure/affiliated-person': {
                name: 'Аффилированные лица',
              },
              '/about/share-capital/information-disclosure/messages': {
                name: 'Сообщения',
              },
              '/about/share-capital/information-disclosure/material-fact': {
                name: 'Существенные факты',
              },
              '/about/share-capital/information-disclosure/press-releases': {
                name: 'Пресс-релизы',
              },
              '/about/share-capital/information-disclosure/regulatory-documents': {
                nameEng: 'Regulatory documents',
              },
            },
          },
        },
      },

      '/about/debt-obligation': {
        name: 'Долговые обязательства',
        nameEng: 'Debt',
        redirect: '/about/debt-obligation/bonds',
        links: {
          '/about/debt-obligation/bonds': {
            name: 'Облигации',
            nameEng: 'Bonds',
          },
          '/about/debt-obligation/credit-ratings': {
            name: 'Кредитные рейтинги',
            nameEng: 'Credit ratings',
          },
          '/about/debt-obligation/pik-corporation': {
            name: 'ПИК Корпорация',
            nameEng: 'PIK Corporation',
          },
        },
      },

      '/about/sustainability': {
        name: 'Устойчивое развитие',
        nameEng: 'Sustainability',
      },

      '/about/contacts': {
        name: 'Контакты',
        nameEng: 'Contacts',
        redirect: '/about/contacts/main-office',

        links: {
          '/about/contacts/main-office': {
            name: 'Главный офис',
            nameEng: 'Head office',
          },
          '/about/contacts/investor-relations': {
            name: 'Инвесторам и акционерам',
            nameEng: 'Investor relations',
          },
          '/about/contacts/media': {
            name: 'Пресса',
            nameEng: 'Media',
          },
          '/about/contacts/combating-corruption': {
            name: 'Противодействие коррупции',
            nameEng: 'Combating corruption',
          },
        },
      },
    },
  },
};
