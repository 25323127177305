export const actionTypes = {
  INIT_APP: 'INIT_APP',
  UPDATE_USER_AGENT: 'UPDATE_USER_AGENT',
  CHANGE_LANG: 'CHANGE_LANG',
};

export const initApp = (options) => ({
  type: actionTypes.INIT_APP,
  options,
});

export const changeLang = (lang) => ({
  type: actionTypes.CHANGE_LANG,
  lang,
});

export const updateUserAgent = (userAgent) => ({
  type: actionTypes.UPDATE_USER_AGENT,
  userAgent,
});
