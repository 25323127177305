import styled from 'styled-components';
import { media } from '@pik/pik-ui';

export const Container = styled.div`
  min-width: 290px;

 @media ${media.max1024} {
    display: none;
  }
`;

export const ListContainer = styled.ul`
  position: sticky;
  top: 16px;
  left: 0;
  
  ${({ isBottom }) => isBottom && `
    position: absolute;
    bottom: 0;
  `}
`;

export const MenuItem = styled.li`
  height: 100%;
  user-select: none;
  white-space: nowrap;
  margin-bottom: 16px;
  
  a {
    opacity: 1;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    transition: color .3s ease;
    font-family: Graphik;
    font-weight: 500;
    color: #A0A0A0;
    text-transform: none;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    
    &:hover {
      color: #A0A0A0;
    }
    
    &-active {
      color: #000 !important;
    }
    
    ${({ node }) => node && 'color: #222 !important;'}
    ${({ active }) => active && 'color: #000000;'}
  }
`;

export const InnerList = styled.div`
  margin-left: 30px;
`;
