import styled, { createGlobalStyle } from 'styled-components';
import { media } from '@pik/pik-ui';

export const Container = styled.div`
  padding: 0 0px;
  height: 100%;
  position: relative;
  
  @media ${media.desktop1024} {
    display: none;
  }
`;

export const ActiveLink = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  svg {
    margin-left: 7px;
    transition: transform 0.3s ease;
    
  ${({ open }) => open && `
    transform: rotate(180deg);
  `}
`;

export const ActiveLinkText = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 26px 38px 15px 28px;
  border-bottom: 1px solid #E6E6E6;
  font-family: Graphik;
  font-weight: 500;
  font-size: 18px;
  color: #4D4D4D;
  user-select: none;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  
  & svg {
    fill: #969BA5;
  }
  
  @media ${media.max768} {
    font-size: 14px;
  }
`;

export const LinksContainer = styled.div`
  position: absolute;
  left: 0;
  top: 65px;
  height: auto;
  width: 100%;
  overflow: hidden;
  transition: visibility .35s ease-in-out;
  visibility: hidden;
  z-index: 1;
  
  ${({ open }) => open && `
    visibility: visible;
    transition: none;
  `}
 
`;

export const Menu = styled.div`
  position: relative;
  padding: 0;
  height: auto;
  width: auto;
  background: #fff;
  transform: translateY(-100%);
  transition: transform .35s ease-in-out;
 
  ${({ open }) => open && `
    transform: translateY(0);
  `}
`;

export const MenuList = styled.ul`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;


export const GlobalBlackoutStyle = createGlobalStyle`
  ${({ open }) => open && `
    .blackout {
      visibility: visible;
      opacity: 1;
      z-index: 99;
    }
  `}
`;

export const MenuItem = styled.li`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  user-select: none;
  white-space: nowrap;
  padding: 19px 28px;
  border-bottom: 1px solid #E6E6E6;
  overflow: hidden;
  
  &.active {
    color: #000000 !important;
  }
`;

export const Link = styled.a`
    opacity: 1;
    height: 100%;
    width: 100%;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    transition: color .3s ease;
    font-family: Graphik;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0;
    font-size: 16px;
    color: #A0A0A0;
    
    @media ${media.max768} {
      font-size: 15px;
    }
    
    &.active {
      color: #000000 !important;
    }
 
`;
