import {
  takeEvery, put, call,
} from 'redux-saga/effects';

import { apiSend } from './api';

import {
  actionTypes,
  sendRequest,
  sendSuccess,
  sendError,
} from './actions';

const createText = (data) => {
  const keys = Object.keys(data);
  let string = '';

  keys.forEach(key => {
    if (data[key]) {
      string += `${key}: ${data[key]}\n`;
    }
  });

  return string;
};

function* sendSaga({ data }) {
  try {
    yield put(sendRequest());
    const text = createText(data);
    yield call(() => apiSend(text));
    yield put(sendSuccess());
  } catch (error) {
    console.error(error);
    yield put(sendError(error));
  }
}

export default function* watchSagas() {
  yield takeEvery(actionTypes.SEND, sendSaga);
}
