import { applyMiddleware, createStore, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';

import { all } from 'redux-saga/effects';

import app from './app';
import appClient from './appClient';
import contact from './contact';
import marketDays from './marketDays';

const combinedReducer = combineReducers({
  appService: app.reducer,
  appClientService: appClient.reducer,
  contactService: contact.reducer,
  marketDaysService: marketDays.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return combinedReducer(state, action);
};

function* rootSaga() {
  yield all([
    app.sagas(),
    contact.sagas(),
    marketDays.sagas(),
  ]);
}

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line import/no-extraneous-dependencies
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducer, bindMiddleware([sagaMiddleware]));

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const wrapper = createWrapper(makeStore, { debug: false });
