import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import Message from '../icons/Message';

import { S } from './styles';
import { showContactModal } from '../../services/contact/actions';

const Chat = ({showContactModalAction}) => (
  <S.Container>
    <S.ButtonWrapper onClick={showContactModalAction}>
      <Message />
    </S.ButtonWrapper>
  </S.Container>
);

Chat.propTypes = {
  showContactModalAction: PropTypes.func.isRequired,
};

const mapStateToProps = ({ appClientService }) => ({
  screenType: appClientService.screenType,
});

const mapDispatchToProps = {
  showContactModalAction: showContactModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
