import styled from 'styled-components';
import { media, styleHelpers } from '@pik/pik-ui';

export const S = {};

S.Footer = styled.div`
     width: 100%;
     background-color: #F8F9FB;
     & * {
        box-sizing: border-box;
     }
`;

S.FooterWrapper = styled.div`
     display: flex;
     justify-content: space-between;
     align-items: center;
     min-height: 148px;
     @media ${media.max768} {
        position: relative;
        flex-direction: column;
        align-items: flex-start;
        min-height: auto;
        padding-top: 34px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 31px;
     }
`;

S.FooterInfoWrapper = styled.div`
    display: flex;
    margin-left: 16px;
    
    @media ${media.max768} {
        flex-direction: column;
        margin-left: 0;
    }
`;

S.FooterLogo = styled.a`
  margin-right: 60px;
  max-height: 16px;
  display: flex;
  align-items: center;

  path {
    fill: ${styleHelpers.colors.secondaryLight};
  }
`;

S.FooterAddressIcon = styled.div`
    position: absolute;
    top: 2px;
    left: -16px;
    @media ${media.max768} {
        position: static;
        display: none;
    }
`;

S.FooterAddress = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 208px;
    @media ${media.max768} {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
    }
`;

S.FooterAddressTitle = styled.div`
    @media ${media.max768} {
        margin-top: 8px;
        & * {
            font-size: 16px;
            line-height: 24px;
        }
    }
`;

S.FooterAddressDescription = styled.div`
    margin-top: 4px;
    @media ${media.max768} {
        margin-top: 8px;
        & * {
            font-size: 14px;
            line-height: 18px;
        }
    }
`;

S.FooterContactsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-height: 148px;
    padding-top: 40px;
    
    @media ${media.max768} {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        min-height: auto;
        padding-top: 27px;
        flex-direction: column;
    }
`;

S.FooterPhoneSocialsWrapper = styled.div`
    display: flex;
`;

S.FooterPhone = styled.a`
    margin-top: 2px;
    margin-right: 30px;
    @media ${media.max768} {
        position: absolute;
        top: 34px;
        left: 15px;
        margin:0;
        
        & * {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
        }
    }
`;

S.FooterSocials = styled.div`
    display: flex;
    padding-right: 2px;

`;

S.FooterSocialLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    margin-right: 20px;
    svg path {
        fill: ${styleHelpers.colors.grayDark}!important;
    }

`;

S.FooterPrivacy = styled.a`
    display: flex;
    justify-content: flex-end;
    margin-top: 28px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: ${styleHelpers.colors.black};
    @media ${media.max768} {
        margin-top: 28px;
    }
`;
