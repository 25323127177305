import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';

import langList from '../../../../common/constants/langList';
import hosts from '../../../../common/constants/hosts';

import Link from '../../../Link';

import { LangWrapper } from './styles';

const ChangeLocation = ({ router, haveEngVersion, lang }) => {
  let engColor = '#A0A0A0';
  let rusColor = '#000000';
  let rusText = 'Ру';
  let isEng = false;
  const splitPath = router.asPath.split('?')[0];

  if (lang === langList.ENG) {
    engColor = '#000000';
    rusColor = '#A0A0A0';
    rusText = 'Ru';
    isEng = true;
  }

  if (!haveEngVersion) {
    return null;
  }

  return (
    <LangWrapper className={isEng ? 'english-version' : ''}>
      <Link
        active={!isEng}
        style={{ color: rusColor }}
        withoutQuery
        href={`//${hosts[langList.RU]}${splitPath}`}
      >
        {rusText}
      </Link>
      <Link
        active={!!isEng}
        style={{ color: engColor }}
        withoutQuery
        href={`//${hosts[langList.ENG]}${splitPath}`}
      >
        En
      </Link>
    </LangWrapper>
  );
};

const mapStateToProps = ({ appService }) => ({
  lang: appService.lang,
});

export default withRouter(connect(mapStateToProps)(ChangeLocation));
