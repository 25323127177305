import styled from 'styled-components';
import { media } from '@pik/pik-ui';

const S = {};

S.AntiFraudBanner = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 24px;
  
  @media ${media.max768}{
    padding: 0 16px;
  }
`;

export default S;
