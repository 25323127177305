import React from 'react';
import NextLink from 'next/link';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';

const Link = ({
  router, target, href, children, active, withoutQuery, ...props
}) => {
  let className = 'Link';
  const { pathname } = router;

  if (pathname === href || active) {
    className = `${className} Link-active`;
  }

  return (
    <NextLink href={href}>
      <a {...props} className={className} target={target}>{children}</a>
    </NextLink>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  target: PropTypes.string,
  active: PropTypes.bool,
  withoutQuery: PropTypes.bool,
  router: PropTypes.object.isRequired,
};

Link.defaultProps = {
  target: '',
  active: false,
  withoutQuery: false,
};

export default withRouter(Link);
