import React from 'react';

export default () => (
  <svg width="96" height="96" xmlns="http://www.w3.org/2000/svg" className="InverseForm-wrapperSvg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 48C0 21.4945586 21.4945586 0 48 0s48 21.4945586 48 48-21.4945586 48-48 48S0 74.5054414 0 48z" fill="#DADEE6" fillRule="nonzero" />
      <g fillRule="nonzero">
        <path d="M63.4327068 18.1729064H31.45225564c-6.23120301 0-9.41390978 3.16995074-9.41390978 9.43300493V88.5458128c.7669173.4802956 1.55300752.9605911 2.35827068 1.4024631V30.1995074H70.2582707v59.7103448c.8819549-.4802955 1.7447368-1.0182266 2.5883458-1.5561576V27.60591133c0-6.26305419-3.1635338-9.43300493-9.4139097-9.43300493z" fill="#002480" />
        <path d="M70.2582707 89.9098522V30.1995074H24.39661654v59.7487685c6.78721805 3.7463054 14.60977446 5.8980295 22.91165416 5.8980295 8.3210526-.0192118 16.143609-2.1709359 22.95-5.9364532z" fill="#DADEE6" />
        <path d="M53.4052632 25.24285714c0 .61477833-.4984963 1.09507389-1.0928572 1.09507389h-9.7206767c-.6135338 0-1.0928571-.49950738-1.0928571-1.09507389 0-.61477832.4984962-1.09507389 1.0928571-1.09507389h9.7206767c.5943609 0 1.0928572.48029557 1.0928572 1.09507389z" fill="#FFF" />
      </g>
      <g fill="#FFF" fillRule="nonzero">
        <path d="M88.2854224 62.3399128c0 2.4609075-2.0150779 4.4603948-4.4951737 4.4603948H49.65017967c-2.48009583 0-4.49517368-1.9994873-4.49517368-4.4603948V42.61420149c0-2.46090746 2.01507785-4.46039477 4.49517368-4.46039477H83.7902487c2.4800958 0 4.4951737 1.99948731 4.4951737 4.46039477V62.3399128z" />
        <path d="M56.1798069 72.8179954c-.3487634.3460651-.9300359.3460651-1.27879936 0l-4.32079194-6.0369136c-.34876348-.3460651-.34876348-.9228403 0-1.2689054h9.9203833c.3487635.3460651.3487635.9228403 0 1.2689054l-4.320792 6.0369136z" />
      </g>
      <g transform="translate(55.035088 49.489362)" fill="#002480">
        <ellipse cx="3.0877193" cy="3.06382979" rx="3.0877193" ry="3.06382979" />
        <ellipse cx="11.5789474" cy="3.06382979" rx="3.0877193" ry="3.06382979" />
        <ellipse cx="20.0701754" cy="3.06382979" rx="3.0877193" ry="3.06382979" />
      </g>
    </g>
  </svg>
);
