import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import {
  Typography, styleHelpers, Icons,
} from '@pik/pik-ui';

import Link from '../Link';
import ChangeLocation from './components/ChangeLocation';
import langList from '../../common/constants/langList';
import MobileNavBar from './components/MobileNavBar';

import HeaderBanner from '../HeaderBanner';

import { S } from './styles';

const routes = require('../../server/routes');

const checkActive = (url, pathname, isFirst) => {
  const aboutPath = '/about/info';
  const financePath = '/about/financial-performance';
  const corporatePath = '/about/corporate-governance';
  const sustainablePath = '/about/sustainable-development';
  const ordersPath = '/about/orders';

  if (aboutPath === url && isFirst) {
    return (
      pathname === aboutPath
      || pathname.includes(financePath)
      || pathname.includes(corporatePath)
      || pathname.includes(sustainablePath)
      || pathname.includes(ordersPath)
    );
  }

  return pathname.includes(url);
};

const renderLink = (linkData, pathname, isEng) => {
  const { name, url } = linkData;
  const isActive = checkActive(url, pathname, true);
  const color = isActive ? styleHelpers.colors.black : '#A0A0A0';
  let href = url;

  if (href === '/about/contacts') {
    href = '/about/contacts/main-office';
  }

  if (href === '/about/debt-obligation') {
    href = '/about/debt-obligation/bonds';
  }

  if (href === '/about/news-and-reports') {
    href = '/about/news-and-reports/news';
  }

  if (href === '/about/share-capital') {
    href = '/about/share-capital/share-information';
  }

  if (href === '/about/share-capital') {
    href = '/about/share-capital/share-information';
  }

  return (
    <S.HeaderLink href={href} key={name} className={isEng ? '' : 'ru-version'}>
      <Typography
        type="interface1"
        weight="medium"
        color={color}
      >
        {name}
      </Typography>
    </S.HeaderLink>
  );
};

const renderNavBarLink = (linkData, pathname) => {
  const { name, url } = linkData;
  const isActive = checkActive(url, pathname);
  let href = url;

  if (href === '/about/financial-performance') {
    href = '/about/financial-performance/main-indicators';
  }
  if (href === '/about/corporate-governance') {
    href = '/about/corporate-governance/governance-structure';
  }
  if (href === '/about/share-capital/information-disclosure') {
    href = '/about/share-capital/information-disclosure/normative-document';
  }

  return (
    <S.SectionMenuLink href={isActive ? '' : href} key={name}>
      <Typography
        type="subTitleOne"
        weight="medium"
        color={isActive ? styleHelpers.colors.black : '#A0A0A0'}
      >
        {name}
      </Typography>
    </S.SectionMenuLink>
  );
};

const renderNavBar = (navBarLinks, pathname) => {
  if (!navBarLinks || !navBarLinks.length) {
    return null;
  }

  return (
    <S.Menu>
      <S.ResponsiveContainer>
        <S.SectionMenu>
          <S.SectionMenuLinks>
            {navBarLinks.map((item) => renderNavBarLink(item, pathname))}
          </S.SectionMenuLinks>
        </S.SectionMenu>
      </S.ResponsiveContainer>
      <S.PageMenu />
    </S.Menu>
  );
};

const checkEngVersion = (path, object) => {
  const keys = Object.keys(object);

  return keys.some((key) => {
    if (key !== path) {
      if (object[key].links) {
        return checkEngVersion(path, object[key].links);
      }

      return false;
    }

    return object[path] && object[path].nameEng;
  });
};

const Header = ({
  links, navBarLinks, navListLinks, pathname, lang,
}) => {
  const isEng = lang === langList.ENG;
  const router = useRouter();
  const haveEngVersion = checkEngVersion(router.pathname, routes);

  let isMobileNav = true;

  if (navListLinks) {
    if (!navListLinks.length) {
      isMobileNav = false;
    }
  } else {
    isMobileNav = false;
  }

  return (
    <>
      <S.Header>
        <S.ResponsiveContainer>
          <S.HeaderWrapper>
            <S.HeaderNav>
              <S.HeaderLogo className={isEng ? '' : 'ru-version'}>
                <Link href="/">
                  <Icons type={isEng ? 'logoEng' : 'logo'} />
                </Link>
              </S.HeaderLogo>
              <S.HeaderLinks>
                {links.map((item) => renderLink(item, pathname, isEng))}
              </S.HeaderLinks>
            </S.HeaderNav>
            <S.ChangeLocationWrapper>
              <ChangeLocation haveEngVersion={haveEngVersion} />
            </S.ChangeLocationWrapper>
          </S.HeaderWrapper>
        </S.ResponsiveContainer>
      </S.Header>
      {renderNavBar(navBarLinks, pathname)}
      {isMobileNav && <MobileNavBar mobileLinks={navListLinks} pathname={pathname} /> }
      <HeaderBanner />
    </>
  );
};

Header.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  navBarLinks: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = ({ appService }) => ({
  lang: appService.lang,
});

export default connect(mapStateToProps)(Header);
