import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';

import Link from '../Link';

import {
  Container,
  MenuItem,
  ListContainer,
} from './styles';

class NavList extends Component {
  static propTypes = {
  };

  state = {
    isBottom: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { isBottom } = this.state;
    const { pageYOffset } = window;
    const appWrapper = document.getElementById('AppWrapper');
    const wrapperBottom = appWrapper.offsetTop + appWrapper.offsetHeight;
    const wrapperPosition = wrapperBottom - pageYOffset;

    if (!this.listBottom) {
      this.listBottom = this.list.offsetHeight + this.list.offsetTop;
    }

    if (!isBottom && (this.listBottom >= wrapperPosition)) {
      return this.setState({ isBottom: true });
    }

    if (isBottom && (this.listBottom < wrapperPosition)) {
      return this.setState({ isBottom: false });
    }
  };

  checkActive = (url, pathname) => pathname.includes(url);

  renderLink = ({ url, name }, router) => {
    return (
      <MenuItem key={url} active={this.checkActive(url, router.pathname)}>
        <a href={url}>{name}</a>
      </MenuItem>
    );
  };

  render() {
    const { isBottom } = this.state;
    const { links, router } = this.props;

    return (
      <Container>
        <ListContainer isBottom={isBottom} ref={el => this.list = el}>
          {links.map(item => this.renderLink(item, router))}
        </ListContainer>
      </Container>
    );
  }
}

NavList.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  router: PropTypes.object,
};

export default withRouter(NavList);
