import React, { Component } from 'react';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import {
  ModalSimple, TextField, Button, Icons, TextArea, Select,
} from '@pik/pik-ui';

import {
  Container,
  Title,
  FieldsContainer,
  FieldWrapper,
  SubText,
  SuccessContainer,
  SuccessText,
  SuccessAnimation,
  ErrorText,
  SuccessButton,
  CheckPhone,
  CheckPhoneBack,
  PhoneWrapper,
  Icon,
  ModalContent,
} from './styles';

import CodeApproveInverse from './components/CodeApproveInverse';
import BackArrow from './components/icons/BackArrow';
import Phone from './components/icons/Phone';

import {
  hideContactModal,
  sendCheckPhone,
  backToForm,
} from '../../services/contact/actions';

import animationData from './animation';

const CONTENT_LENGTH = 2000;

const DEFAULT_STATE = {
  data: {
    name: '',
    phone: '',
    email: '',
    content: '',
    blockName: '',
    files: [],
  },
  validate: {
    name: false,
    phone: false,
    email: false,
    content: true,
  },
  errors: {
    name: false,
    phone: false,
    email: false,
    content: false,
  },
  isPaused: false,
};

class ContactModal extends Component {
  state = { ...DEFAULT_STATE };

  componentDidUpdate({ active: prevActive, sent: prevSent }) {
    const { sent } = this.props;

    if (!prevSent && sent) {
      setTimeout(() => this.setState({ isPaused: true }), 800);
    }
  }

  handleClose = () => {
    this.props.hideContactModalAction();
  };

  handleChange = (name, val) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [name]: val,
      },
    }, () => {
      this.setContentError();
    });
  };

  handleClickBackArrow = () => this.props.backToFormAction();

  renderCheckPhone = () => (
    <CheckPhone>
      <CheckPhoneBack onClick={this.handleClickBackArrow}>
        <BackArrow />
      </CheckPhoneBack>
      <PhoneWrapper>
        <Phone />
      </PhoneWrapper>
      <CodeApproveInverse
        content={this.props.data}
        phone={this.state.data.phone}
        data={this.state.data}
        message={this.props.message}
      />
    </CheckPhone>
  );

  setNameError = (val) => {
    const isValid = val.length > 0 && val.trim().length > 0;
    this.validateInput(isValid, 'name');
  };

  setContentError = () => {
    const { data: { content } } = this.state;
    const isValid = !!content.trim().length;
    this.validateInput(isValid, 'content');
  };

  validateInput = (isValid, name) => {
    const { data } = this.props;
    const { validate, errors } = this.state;

    if (name) {
      this.setState({
        validate: {
          ...validate,
          [name]: isValid,
        },
        errors: {
          ...errors,
          [name]: !isValid ? data[name].error : false,
        },
      });
    }

    return null;
  };

  getClearPhoneString = () => this.state.data.phone.replace(/[^0-9]+/g, '');

  renderPhone = () => {
    const {
      data: {
        phone,
      },
    } = this.props;
    const { data, validate } = this.state;
    const ruFormat = phone.format === 'ru';
    const fieldType = ruFormat ? 'newTel' : 'text';
    const mask = ruFormat ? null : '+99 999 9999999';

    if (!ruFormat && !validate.phone) {
      this.validateInput(true, 'phone');
    }

    return (
      <FieldWrapper>
        <TextField
          type={fieldType}
          name="phone"
          placeholder={phone.placeholder}
          mask={mask}
          inputStyle="accent"
          value={data.phone}
          onChange={this.handleChange}
          onValidate={(name, isValid) => this.validateInput(isValid, name)}
        />
      </FieldWrapper>
    );
  }

  renderFields = () => {
    const {
      data: {
        name,
        email,
        content,
        topics,
        topic,
      },
    } = this.props;
    const { data, errors } = this.state;

    return (
      <FieldsContainer>
        <FieldWrapper>
          <TextField
            type="text"
            name="name"
            placeholder={name.placeholder}
            inputStyle="accent"
            onBlur={() => this.setNameError(data.name)}
            error={errors.name}
            value={data.name}
            onChange={this.handleChange}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            type="email"
            name="email"
            placeholder={email.placeholder}
            inputStyle="accent"
            value={data.email}
            onChange={this.handleChange}
            onValidate={(name, isValid) => this.validateInput(isValid, name)}
          />
        </FieldWrapper>
        {this.renderPhone()}
        <FieldWrapper className="select">
          <Select
            size="l"
            type="accent"
            list={topics}
            name="subject"
            value={data.subject}
            onChange={this.handleChange}
            placeholder={topic.placeholder}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextArea
            type="text"
            limit={CONTENT_LENGTH}
            name="content"
            textareaStyle="accent"
            placeholder={content.placeholder}
            value={data.content}
            onChange={this.handleChange}
          />
        </FieldWrapper>
      </FieldsContainer>
    );
  };

  renderError = () => {
    const { error, data: { errorMessage } } = this.props;

    if (!error) {
      return null;
    }

    return (
      <ErrorText>
        {errorMessage}
      </ErrorText>
    );
  }

  renderForm = () => {
    const {
      data: {
        title,
        agreement,
        submitButton,
      },
      sendCheckPhoneAction,
    } = this.props;

    const {
      data,
      validate: {
        name, phone, email, content,
      },
    } = this.state;

    const validated = name && phone && email && content && !!data.name && !!data.phone && !!data.email && !!data.subject;

    return (
      <Container>
        <Title>{title}</Title>
        {this.renderFields()}
        <Button
          size="l"
          type="primaryB"
          id="contactUsSubmitButton"
          onClick={() => sendCheckPhoneAction(this.getClearPhoneString())}
          disabled={!validated}
          text={submitButton}
          fullWidth
        />
        {this.renderError()}
        <SubText dangerouslySetInnerHTML={{ __html: agreement }} />
      </Container>
    );
  };

  renderSuccessIcon = () => {
    const { somethingWrong } = this.props;
    const { isPaused } = this.state;

    if (somethingWrong) {
      return (
        <Icon>
          <Icons type="cross" size="xxl" />
        </Icon>
      );
    }

    return (
      <Lottie
        options={{ animationData }}
        height={70}
        width={73}
        isPaused={isPaused}
      />
    );
  };

  renderSuccess = () => {
    const { data: { successText, successButtonText }, somethingWrong } = this.props;

    const text = somethingWrong ? 'Что-то пошло не так' : successText;

    return (
      <SuccessContainer>
        <SuccessAnimation>
          { this.renderSuccessIcon() }
        </SuccessAnimation>
        <SuccessText>
          {text}
        </SuccessText>
        <SuccessButton onClick={this.handleClose}>
          {successButtonText}
        </SuccessButton>
      </SuccessContainer>
    );
  };

  renderContent = () => {
    const { sent, checkPhone } = this.props;

    if (sent) {
      return this.renderSuccess();
    }

    if (checkPhone) {
      return this.renderCheckPhone();
    }

    return this.renderForm();
  };

  render() {
    const { active } = this.props;

    const styles = `
      max-width: 544px;
      padding: 38px 32px 25px;
    `;

    return (
      <ModalSimple
        styles={styles}
        root="modal"
        onClose={this.handleClose}
        active={active}
      >
        <ModalContent>
          {this.renderContent()}
        </ModalContent>
      </ModalSimple>
    );
  }
}

const mapStateToProps = ({ contactService }) => ({
  active: contactService.active,
  sent: contactService.sent,
  checkPhone: contactService.checkPhone,
  loading: contactService.loading,
  error: contactService.error,
  somethingWrong: contactService.somethingWrong,
});

const mapDispatchToProps = {
  hideContactModalAction: hideContactModal,
  backToFormAction: backToForm,
  sendCheckPhoneAction: sendCheckPhone,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal);
