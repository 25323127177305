import { createHash } from '@pik/pik-utils';

import {
  takeEvery, put, call, select,
} from 'redux-saga/effects';

import {
  actionTypes,
  sendContactRequest,
  sendContactSuccess,
  sendContactError,
  updateSavedPhone,
  sendContact,
  checkSmsCodeError,
} from './actions';

import { apiSendContact, apiPhoneCheck } from './api';

function* sendContactSaga({ values }) {
  try {
    yield put(sendContactRequest());

    const { email, ...rest } = values;

    yield call(() => apiSendContact(JSON.stringify({
      ...rest,
      from: email,
      url: location.href,
      source: 10,
    })));

    yield put(sendContactSuccess());
  } catch (error) {
    console.error(error);
    const somethingWrong = !!error && !!error.data && !!error.data.error;
    yield put(sendContactError(error, somethingWrong));
  }
}

function* sendCheckPhoneSaga({ phone }) {
  const hash = createHash.sms(`+${phone}`);

  try {
    // const savedPhone = yield select(state => state.contactService.savedPhone);

    // if (savedPhone !== phone) {
    yield call(() => apiPhoneCheck(JSON.stringify({
      phone, service: 'confirmFeedbackSmsPikru',
    }), hash));
    yield put(updateSavedPhone(phone));
    // }
  } catch (error) {
    console.error(error);
  }
}

function* checkSmsCodeSaga({ code, data }) {
  try {
    const phone = yield select((state) => state.contactService.savedPhone);
    const { message } = yield call(() => apiPhoneCheck(JSON.stringify({
      service: 'confirmFeedbackSmsPikru',
      phone,
      code,
    })));

    if (message === 'OK') {
      // console.log(message, data);
      yield put(sendContact(data));
    }
  } catch (error) {
    yield call(() => checkSmsCodeError(error));
    console.error(error);
  }
}
export default function* watchSagas() {
  yield takeEvery(actionTypes.SEND_CONTACT, sendContactSaga);
  yield takeEvery(actionTypes.SEND_CHECK_PHONE, sendCheckPhoneSaga);
  yield takeEvery(actionTypes.CHECK_SMS_CODE, checkSmsCodeSaga);
}
