import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Typography, styleHelpers, RespContainer, Icons, IconsPack,
} from '@pik/pik-ui';
import langList from '../../common/constants/langList';

import { S } from './styles';

const renderInfoBlock = (address, lang) => {
  const { title, description } = address;
  return (
    <S.FooterInfoWrapper itemprop="address">
      <S.FooterAddress itemScope itemType="http://schema.org/PostalAddress">
        <S.FooterAddressIcon>
          <Icons type="pinSolid2" size="xs" />
        </S.FooterAddressIcon>
        <S.FooterAddressTitle>
          <Typography type="subTitleTwo" weight="medium" color={styleHelpers.colors.grayDark}>{title}</Typography>
        </S.FooterAddressTitle>
        <S.FooterAddressDescription itemProp="streetAddress"><Typography type="caption">{description}</Typography></S.FooterAddressDescription>
      </S.FooterAddress>
    </S.FooterInfoWrapper>
  );
};

const renderSocialLink = (data) => {
  const { name, url } = data;

  if (name === 'instagram' || name === 'facebook') {
    return null;
  }

  if (name === 'instagram') {
    return (
      <S.FooterSocialLink key={name} href={url} target="_blank" rel="noopener noreferrer"><Icons type={name} size="m" /></S.FooterSocialLink>
    );
  }

  return (
    <S.FooterSocialLink key={name} href={url} target="_blank" rel="noopener noreferrer"><IconsPack type="filled" name={name} size="l" /></S.FooterSocialLink>
  );
};

const formatPhoneToLink = (phoneNumber) => {
  const re = /\W/g;
  const link = phoneNumber.replace(re, '');

  return `tel:${link}`;
};

const renderContactsBlock = (socials, phoneNumber, privacy) => {
  const { text, url } = privacy;

  return (
    <S.FooterContactsWrapper>
      <S.FooterPhoneSocialsWrapper>
        <S.FooterPhone href={formatPhoneToLink(phoneNumber)}>
          <Typography
            type="subTitleTwo"
            color={styleHelpers.colors.black}
            weight="medium"
          >
            <span itemProp="telephone">{phoneNumber}</span>
          </Typography>
        </S.FooterPhone>
        <S.FooterSocials>
          {socials.map((item) => renderSocialLink(item))}
        </S.FooterSocials>
      </S.FooterPhoneSocialsWrapper>
      <S.FooterPrivacy href={url}>{text}</S.FooterPrivacy>
    </S.FooterContactsWrapper>
  );
};

const Footer = ({ data, lang }) => {
  const {
    phoneNumber, address, socials, privacy,
  } = data;

  return (
    <S.Footer>
      <RespContainer>
        <S.FooterWrapper itemScope itemType="http://schema.org/Organization">
          {renderInfoBlock(address, lang)}
          {renderContactsBlock(socials, phoneNumber, privacy)}
        </S.FooterWrapper>
      </RespContainer>
    </S.Footer>
  );
};

Footer.propTypes = {
  data: PropTypes.shape({
    phoneNumber: PropTypes.string.isRequired,
    address: PropTypes.shape({}).isRequired,
    socials: PropTypes.arrayOf.isRequired,
    privacy: PropTypes.shape({}).isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
};

const mapStateToProps = ({ appService }) => ({
  lang: appService.lang,
});

export default connect(mapStateToProps)(Footer);
