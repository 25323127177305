import { actionTypes } from './actions';

const initialState = {
  active: false,
  sent: false,
  loading: false,
  error: null,
  checkPhone: false,
  savedPhone: null,
  somethingWrong: false,
};

const handleShowContactModal = (state) => ({
  ...state,
  active: true,
});

const handleHideContactModal = (state) => ({
  sent: state.sent,
  ...initialState,
});

const handleSendContactRequest = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const handleSendContactSuccess = (state) => ({
  ...state,
  sent: true,
  loading: false,
});

const handleSendContactError = (state, action) => ({
  ...state,
  error: action.error,
  loading: false,
  somethingWrong: action.somethingWrong,
  sent: action.somethingWrong,
});

const handleSendCheckPhone = (state, action) => ({
  ...state,
  checkPhone: true,
});

const handleBackToForm = (state, action) => ({
  ...state,
  checkPhone: false,
});

const handleUpdateSavedPhone = (state, action) => ({
  ...state,
  savedPhone: action.phone,
});

const handleCheckSmsCodeError = (state, action) => ({
  ...state,
  checkSmsCodeError: action.error,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.SHOW_CONTACT_MODAL]: handleShowContactModal,
    [actionTypes.HIDE_CONTACT_MODAL]: handleHideContactModal,
    [actionTypes.SEND_CONTACT_REQUEST]: handleSendContactRequest,
    [actionTypes.SEND_CONTACT_SUCCESS]: handleSendContactSuccess,
    [actionTypes.SEND_CONTACT_ERROR]: handleSendContactError,
    [actionTypes.SEND_CHECK_PHONE]: handleSendCheckPhone,
    [actionTypes.BACK_TO_FORM]: handleBackToForm,
    [actionTypes.UPDATE_SAVED_PHONE]: handleUpdateSavedPhone,
    [actionTypes.CHECK_SMS_CODE_ERROR]: handleCheckSmsCodeError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
