import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Icons } from '@pik/pik-ui';

import {
  Container,
  ActiveLink,
  ActiveLinkText,
  LinksContainer,
  Menu,
  MenuList,
  MenuItem,
  Link,
} from './styles';

const MobileNavBar = ({
  mobileLinks, pathname,
}) => {
  const getActiveCurrentLink = (links) => {
    const activeLink = links.filter((item) => item.url === pathname)[0].name;

    return activeLink;
  };

  const renderCurrentLink = (links, open, setOpen) => (
    <ActiveLink
      open={open}
    >
      <ActiveLinkText onClick={() => setOpen(!open)}>
        {getActiveCurrentLink(mobileLinks, pathname)}
        <Icons type="arrowDownSoft" size="xs" />
      </ActiveLinkText>
    </ActiveLink>
  );

  const renderLink = (name, url) => {
    let isActive = false;
    if (url === pathname) {
      isActive = true;
    }
    return (
      <MenuItem key={url}>
        <Link href={url} className={isActive ? 'active' : ''}>{name}</Link>
      </MenuItem>
    );
  };

  const renderLinks = (links, open) => (
    <LinksContainer open={open}>
      <Menu open={open}>
        <MenuList>
          {links.map((item) => {
            const { name, url } = item;
            return renderLink(name, url, pathname);
          })}
        </MenuList>
      </Menu>
    </LinksContainer>
  );

  const [open, setOpen] = useState(false);

  return (
    <Container>
      <div>
        {renderCurrentLink(mobileLinks, open, setOpen)}
        {renderLinks(mobileLinks, open)}
      </div>
    </Container>
  );
};

MobileNavBar.propTypes = {
  mobileLinks: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  pathname: PropTypes.string.isRequired,
};

const mapStateToProps = ({ appService }) => ({
  lang: appService.lang,
});

export default connect(mapStateToProps)(MobileNavBar);
