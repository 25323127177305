import React, { Component } from 'react';
import proschet from 'proschet';
import langList from '../../../../common/constants/langList';

export default class Timer extends Component {
  state = {
    timer: this.props.timer,
  };

  componentDidMount() {
    this.timer = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getSeconds = proschet(['секунду', 'секунды', 'секунд']);

  tick() {
    const { timer } = this.state;
    const { onComplete } = this.props;

    if (timer === 0) {
      clearInterval(this.timer);

      onComplete();
      return;
    }

    this.setState({ timer: timer - 1 });
  }

  render() {
    const { lang } = this.props;
    const { timer } = this.state;
    let second = 'seconds';

    if (lang === langList.RU) {
      second = this.getSeconds(timer);
    }

    return <span className="Timer">{ `${timer} ${second}` }</span>;
  }
}
