import styled from 'styled-components';
import { media, mixins, styleHelpers } from '@pik/pik-ui';

export const Container = styled.div`
  position: relative;
`;

export const Title = styled.span`
  display: block;
  font-size: 32px;
  color: #26334D;
  letter-spacing: 0;
  margin-bottom: 23px;
  font-weight: 500;
  
  @media ${media.max768} {
    font-size: 24px;
    margin-bottom: 23px;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FieldWrapper = styled.div`
  width: 100%;
  max-width: 480px;
  position: relative;
  margin-bottom: 16px;
  
  @media ${media.max768} {
    max-width: 768px;
  }
  
  ${({ half }) => half && `
    width: 48%
    
    @media ${media.max768} {
      width: 100%;
    }
  `};
  
  &.select * {
    white-space: nowrap;
  }
`;

export const SubText = styled.span`
  display: block;
  font-size: 12px;
  color: #969BA5;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 17px;
  text-align: center;
  padding: 0 70px;
  @media ${media.max768} {
    padding: 0 20px;
  }
`;

export const SuccessContainer = styled.div` 
  z-index: 10;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 75px;
`;

export const SuccessAnimation = styled.div` 
  width: 73px;
  height: 70px;
  overflow: hidden;
  margin: 0 auto;
  outline: none;
`;

export const Icon = styled.div` 
  width: 73px;
  height: 70px;
  margin: 0 auto;
  ${mixins.flex()};
  
  svg {
    fill: ${styleHelpers.colors.primary};
  }
`;

export const Loading = styled.div` 
  span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    display: inline-block;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #fff;

    &:nth-child(2) {
      animation-delay: .2s;
    }

    &:nth-child(3) {
      animation-delay: .4s;
    }
  }
  
  @keyframes blink {
    0% {
      opacity: .2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .2;
    }
  }
`;

export const SuccessText = styled.span` 
  display: block;
  font-weight: 500;
  margin-top: 24px;
  font-size: 32px;
  color: #26334d;
  letter-spacing: 0;
  text-align: center;
  line-height: 40px;
`;

export const SuccessButton = styled.div` 
  border: 1px solid #ccc;
  height: 48px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 75px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 16px;
  color: #4d4d4d;
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
`;

export const ErrorText = styled.span` 
  display: block;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 8px;
  color: #E64646;
`;

export const CheckPhone = styled.div` 
  position: relative;
`;

export const CheckPhoneBack = styled.div` 
  position: absolute;
  top: -48px;
  left: -16px;
  cursor: pointer;
  
  @media ${media.max768} {
    left: 0;
  }
`;

export const PhoneWrapper = styled.div` 
  margin-top: 26px;
`;

export const ModalContent = styled.div`
   width: 480px;
   max-width: 100%;
   @media ${media.max768} {
      width: 100%; 
      min-height: 100vh;
   }
`;


