import React from 'react';

const Message = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <rect x="4" y="2" width="48" height="48" rx="24" />
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.0527 21.9039C18.0527 19.6095 19.6222 17.6267 21.9006 17.3567C23.5386 17.1626 25.633 17 28.0527 17C30.4724 17 32.5669 17.1626 34.2048 17.3567C36.4832 17.6267 38.0527 19.6095 38.0527 21.9039V28.0961C38.0527 30.3905 36.4832 32.3733 34.2048 32.6433C32.5669 32.8374 30.4724 33 28.0527 33C27.6458 33 27.2481 32.9954 26.8602 32.9868L23.7598 35.6712C23.1299 36.3736 22.0527 35.8761 22.0527 34.8829V32.661L21.9006 32.6433C19.6222 32.3733 18.0527 30.3905 18.0527 28.0961V21.9039ZM23.5 26.25C24.1904 26.25 24.75 25.6904 24.75 25C24.75 24.3096 24.1904 23.75 23.5 23.75C22.8096 23.75 22.25 24.3096 22.25 25C22.25 25.6904 22.8096 26.25 23.5 26.25ZM29.25 25C29.25 25.6904 28.6904 26.25 28 26.25C27.3096 26.25 26.75 25.6904 26.75 25C26.75 24.3096 27.3096 23.75 28 23.75C28.6904 23.75 29.25 24.3096 29.25 25ZM32.5 26.25C33.1904 26.25 33.75 25.6904 33.75 25C33.75 24.3096 33.1904 23.75 32.5 23.75C31.8096 23.75 31.25 24.3096 31.25 25C31.25 25.6904 31.8096 26.25 32.5 26.25Z" fill="white" />
    <defs>
      <filter id="filter0_d" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default Message;
