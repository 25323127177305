const APP_PORT = process.env.APP_PORT || '3000';

module.exports = {
  appApi: `http://localhost:${APP_PORT}`,
  pikApi: 'https://api.pik.ru',
  pikStrapi: 'https://strapi.pik.ru',
  uploadApi: 'https://upload.pik.ru',
  pikChatApi: 'https://chat-digital.pik.ru',
  requestProxy: 'https://proxyapi.pik.ru',
};
