import styled from 'styled-components';
import { media } from '@pik/pik-ui';

export const S = {};

S.Container = styled.div`
    position: fixed;
    right: 33px;
    bottom: 23px;
    z-index: 150;
    
    @media ${media.max768} {
        bottom: 24px;
        right: 16px;
    }
   

`;
S.ButtonWrapper = styled.button`
    cursor: pointer;

    & svg {
      fill: #222222;
    }
    
    &:hover svg {
      fill: rgb(0, 0, 0);
    }
  
`;
