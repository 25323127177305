import { actionTypes } from './actions';

const initialState = {
  sent: false,
  loading: false,
  error: null,
};

const handleSendRequest = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const handleSendSuccess = (state) => ({
  ...state,
  sent: true,
  loading: false,
  error: null,
});

const handleSendError = (state, action) => ({
  ...state,
  error: action.error,
  loading: false,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.SEND_REQUEST]: handleSendRequest,
    [actionTypes.SEND_SUCCESS]: handleSendSuccess,
    [actionTypes.SEND_ERROR]: handleSendError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
