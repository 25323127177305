import styled from 'styled-components';
import { media } from '@pik/pik-ui';

export const S = {};

S.ResponsiveContainer = styled.div`
    max-width: 1120px;
    margin: 0 auto;
    
    @media ${media.max1120} {
        max-width: 936px;
    }
    
    @media ${media.max1024} {
        max-width: 100%;
    }
`;

S.Header = styled.div`
  position: relative;
   box-sizing: border-box;
   width: 100%;
   box-shadow: inset 0px -1px 0px #F0F0F5;
`;

S.HeaderWrapper = styled.div`
     display:flex;
     min-height: 64px;
     justify-content: space-between;
     align-items: center;
     
     @media ${media.max1024} {
       display: block
     }
    
`;

S.HeaderLogo = styled.div`
  margin-right: 44px;

  .Icons {
    display: flex;
    align-items: center;
  }

  svg {
    max-height: 16px;
    position: static;
  }
  
  &.ru-version {
    margin-right: 20px;
    svg {
      max-height: initial;
    }
  }
  
  
    @media ${media.max1024} {
      display: flex;
      margin-right: 0;
      justify-content: center;
      border-bottom: 1px solid #F0F0F5;
      
      &.ru-version {
        margin-right: 0;
      }
    }
`;

S.HeaderNav = styled.nav`
    display: flex;
    align-items: center;
    @media ${media.max1024} {
      width: 100%;
      display: block;
    }

`;

S.HeaderLinks = styled.div`
  overflow: scroll hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  
  @media ${media.max1024} {
    padding: 5px 16px 14px 16px;
  }
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

S.HeaderLink = styled.a`
    margin-right: 33px;
    padding-top: 2px;
    span {
      font-size: 15px;
      line-height: 20px;
    }
    
    &.ru-version {
        margin-right: 24px;
    }
    @media ${media.max1120} {
        span {
          font-size: 13px;
        }
    }
    
    @media ${media.max1024} {
    margin-right: 16px;
      &:first-child {
        margin-right: 32px;
      }
      span {
        font-size: 12px;
        line-height: 14px;
      }
    }
`;

S.ChangeLocationWrapper = styled.div`
    @media ${media.max1024} {
        position: absolute;
        top: 12px;
        right: 30px;
    }
`;

S.Menu = styled.div`


`;

S.SectionMenu = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #EAEAEA;
  
  @media ${media.max1024} {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 4px;
  }
`;

S.SectionMenuLinks = styled.div`
  overflow: scroll hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

S.SectionMenuLink = styled.a`
  margin-right: 32px;
  span {
    font-size: 14px;
    line-height: 20px;
  }
  @media ${media.max1024} {
    margin-right: 16px;
    span {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

S.PageMenu = styled.div`


`;
