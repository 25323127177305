import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button } from '@pik/pik-ui';

import Timer from '../Timer';

import {
  Title,
  Text,
  CodeWrapper,
  InputWrapper,
  ButtonWrapper,
  TimeWrapper,
  ErrorWrapper,
  Error,
  ResendCode,
} from './styles';

import { checkSmsCode, sendCheckPhone } from '../../../../services/contact/actions';

class CodeApproveInverse extends Component {
  state = {
    resendCode: false,
    disable: true,
    value: '',
  };

  handlerCode = (name, rawValue) => {
    const value = rawValue.replace(/[^0-9.]/g, '');

    if (value.length > 4) {
      return null;
    }

    this.setState({ disable: value.length !== 4, value });
  };

  sendCode = () => this.props.checkSmsCodeAction(this.state.value, this.props.data);

  onTimerComplete = () => {
    this.setState({ resendCode: true });
  };

  getClearPhoneString = (string) => string.replace(/[^0-9]+/g, '');

  getCodeAgain = (e) => {
    e.preventDefault();
    const { sendCheckPhoneAction, phone } = this.props;
    this.setState({ resendCode: false });

    console.log('sendCheckPhoneAction', sendCheckPhoneAction);

    sendCheckPhoneAction(this.getClearPhoneString(phone));
  };

  renderTimeContent = () => {
    const { content, lang } = this.props;
    const { resendCode } = this.state;
    const {
      codeApprove: {
        timer,
        resend,
      },
    } = content;

    if (resendCode) {
      return (
        <ResendCode onClick={this.getCodeAgain}>
          <svg width="13" height="13" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <path
                d="M6.5.035156c-3.582031 0-6.5 2.90625-6.5 6.480469V7h1v-.484375c0-3.03125 2.457031-5.480469 5.5-5.480469 1.632813 0 3.089844.714844 4.09375 1.839844L9.464844 4H13V.476563l-1.699219 1.691406C10.113281.863281 8.40625.035156 6.5.035156zM12 6v.5C12 9.542969 9.542969 12 6.5 12c-1.640625 0-3.097656-.722656-4.105469-1.859375L3.535156 9H0v3.535156l1.6875-1.6875C2.878906 12.164063 4.589844 13 6.5 13c3.585938 0 6.5-2.914062 6.5-6.5V6h-1z"
                fill="#484848"
                fillRule="nonzero"
              />
            </g>
          </svg>
          {resend}
        </ResendCode>
      );
    }

    return (
      <span>
        {`${timer}`}
        &nbsp;
        <Timer lang={lang} onComplete={this.onTimerComplete} timer={60} />
      </span>
    );
  };

  render() {
    const { error, content } = this.props;
    const { value, disable } = this.state;
    const {
      codeApprove: {
        errorText,
        desc,
        title,
        placeholder,
        button,
      },
    } = content;

    return (
      <div>
        <Title>{title}</Title>
        <Text>{desc}</Text>
        <CodeWrapper>
          <InputWrapper>
            <TextField
              type="text"
              name="code"
              placeholder={placeholder}
              value={value}
              error={error}
              onChange={this.handlerCode}
              size="m"
            />
          </InputWrapper>
          <ErrorWrapper>
            <Error error={error}>{errorText}</Error>
          </ErrorWrapper>
          <ButtonWrapper>
            <Button
              type="primaryB"
              id="phoneCheckButton"
              text={button}
              disabled={disable}
              onClick={() => this.sendCode()}
              size="m"
              fullWidth
            />
          </ButtonWrapper>
        </CodeWrapper>
        <TimeWrapper>
          {this.renderTimeContent()}
        </TimeWrapper>
      </div>
    );
  }
}

const mapStateToProps = ({ appService, contactService }) => ({
  error: contactService.checkSmsCodeError,
  lang: appService.lang,
});

const mapDispatchToProps = {
  checkSmsCodeAction: checkSmsCode,
  sendCheckPhoneAction: sendCheckPhone,
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeApproveInverse);
