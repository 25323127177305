import { actionTypes } from './actions';

const initialState = {
  lang: null,
  userAgent: {},
};

const handleChangeLang = (state, action) => ({
  ...state,
  lang: action.lang,
});

const handleUpdateUserAgent = (state, action) => ({
  ...state,
  userAgent: action.userAgent,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.CHANGE_LANG]: handleChangeLang,
    [actionTypes.UPDATE_USER_AGENT]: handleUpdateUserAgent,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
