import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { parseCookies, setCookie } from 'nookies';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';

import { RespContainer } from '@pik/pik-ui';
import Banner from '@pik-components/banner';

import langList from '../../common/constants/langList';

import S from './styles';

const COOKIE_NAME = 'PIK_ANTI_FRAUD_BANNER_HIDE';

const checkBannerCookie = () => {
  try {
    const cookies = parseCookies();

    return !!cookies && cookies[COOKIE_NAME];
  } catch (error) {
    console.error(error);
  }

  return false;
};

const setBannerCookie = () => {
  try {
    setCookie({}, COOKIE_NAME, '1', {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
    });
  } catch (error) {
    console.error(error);
  }
};

const AntiFraudBanner = ({ lang }) => {
  const [show, setShow] = useState(false);
  const router = useRouter();

  const showBanner = () => {
    setShow(true);
  };

  const hideBanner = () => {
    setShow(false);
    setBannerCookie();
  };

  const handleHideBanner = (e) => {
    e.stopPropagation();
    e.preventDefault();

    hideBanner();
  };

  useEffect(() => {
    if (!checkBannerCookie()) {
      showBanner();
    }
  }, []);

  const bannerProps = {
    type: 'antiFraud',
    className: '',
    title:
      '<span>Противодействие мошенничеству.</span> Проверьте ресурсы, которые используете',
    textColor: '#141414',
    backgroundColor: 'rgba(252, 201, 51, 0.4)',
    button: {
      link: '/fraud-check',
      text: 'Проверить',
      buttonColor: '#141414',
      targetBlank: true,
    },
    onClose: handleHideBanner,
  };

  if (router.pathname !== '/') {
    return null;
  }

  const isEng = lang === langList.ENG;

  if (isEng) {
    return null;
  }

  if (show) {
    return (
      <S.AntiFraudBanner>
        <RespContainer>
          <Banner {...bannerProps} />
        </RespContainer>
      </S.AntiFraudBanner>
    );
  }

  return null;
};

AntiFraudBanner.propTypes = {
  lang: PropTypes.string.isRequired,
};

const mapStateToProps = ({ appService }) => ({
  lang: appService.lang,
});

export default connect(mapStateToProps)(AntiFraudBanner);
