import styled from 'styled-components';
import { media } from '@pik/pik-ui';

export default styled.h1`
  font-family: Graphik;
  font-weight: 500;
  color: #222222;
  letter-spacing: 0;
  font-size: 64px;
  line-height: 74px;
  margin-top: 97px;
  margin-bottom: 96px;
  white-space: pre-wrap;
  
  ${({ subtitle }) => subtitle && `
    font-size: 32px;
    margin-bottom: 32px;
    line-height: 38px;
  `};
  
  ${({ marginBottom }) => marginBottom && `
    margin-bottom: ${marginBottom}px;
  `};
  
  ${({ marginTop }) => marginTop && `
    margin-top: ${marginTop}px;
  `};
  
  @media ${media.max768} {
    font-size: 44px;
    line-height: 48px;
    margin-top: 54px;
    margin-bottom: 32px;
    
    ${({ subtitle }) => subtitle && `
     font-size: 20px;
     line-height: 28px;
     margin-bottom: 10px;
  `}
  }
`;
