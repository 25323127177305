import styled from 'styled-components';
import { media } from '@pik/pik-ui';

export const Title = styled.h2`
  padding-top: 27px;
  font-weight: 500;
  font-size: 32px;
  color: #26334d;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 4px;
`;

export const Text = styled.h2`
  text-align: left;
  max-width: 544px;
  margin: 0 auto 28px;
  font-size: 16px;
  color: #484848;
  letter-spacing: 0;
  line-height: 24px;
  
  @media ${media.max768} {
    font-size: 16px;
    color: #4D4D4D;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 16px;
  }
`;

export const CodeWrapper = styled.div`
  margin-bottom: 73px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  
  @media ${media.max768} {
    flex-direction: column;
    margin-bottom: 25px;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  min-width: 192px;
  margin-left: 12px;
  @media ${media.max768} {
    width: 100%;
    margin-top: 25px;
    margin-left: 0;
  }
`;

export const Button = styled.button`
  border-radius: 6px;
  background: #26334D;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  transition: background 0.4s ease, color 0.4s ease;
  min-width: 192px;
  height: 48px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-left: 12px;

  &:hover {
    background: #0F1B33;
  }

  ${({ disable }) => disable && `
    color: #CCCCCC;
    background: #F2F2F2;
    pointer-events: none;

    &:hover {
      color: #CCCCCC;
      background: #F2F2F2;
    }
  `}
  
  @media ${media.max768} {
    width: 100%;
    margin-left: 0;
    height: 48px;
    font-size: 15px;
    margin-top: 24px;
  }
`;

export const TimeWrapper = styled.div`
  font-size: 13px;
  color: #959ba6;
  letter-spacing: 0;
  line-height: 16px;
`;

export const ErrorWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export const Error = styled.div`
  position: absolute;
  text-align: left;
  color: #FF0C0C;
  letter-spacing: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  font-weight: 400;
  font-size: 11px;
  top: 6px;
  
  ${({ show }) => show && `
    opacity: 1;
    visibility: visible;
  `}
`;

export const ResendCode = styled.span`
  font-size: 14px;
  color: #484848;
  letter-spacing: 0;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all .4s ease;
  
  svg {
    margin-right: 4px;
    transition: all .4s ease;
  }
  
  &:hover {
    color: #f63;
    
    svg {
      transform: rotate(180deg);
      
      g {
        path {
          fill: #f63;
        }
      }
    }
  }
`;
