import { actionTypes } from './actions';

const initialState = {
  screenType: null,
};

const handleChangeScreenType = (state, action) => ({
  ...state,
  screenType: action.screenType,
});

const handleUpdateCustomer = (state, action) => ({
  ...state,
  customer: action.customer,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.CHANGE_SCREEN_TYPE]: handleChangeScreenType,
    [actionTypes.UPDATE_CUSTOMER]: handleUpdateCustomer,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
