import langList from '../constants/langList';
import routes from '../../server/routes';

const ROOT_PATH = '/';
const ABOUT_PATH = '/about';

const findKeyForNavList = (pathname) => {
  if (pathname === ROOT_PATH) {
    return ROOT_PATH;
  }

  const node = routes[ROOT_PATH].links;
  const aboutLinks = Object.keys(node[ABOUT_PATH].links);
  const slug = `${ABOUT_PATH}/${pathname.split('/')[2]}`;

  if (pathname === ABOUT_PATH) {
    return ROOT_PATH;
  }

  if (aboutLinks.includes(slug)) {
    return ABOUT_PATH;
  }

  return slug;
};

const getThirdSlug = (pathname) => {
  const list = pathname.split('/');
  list.pop();

  return list.join('/');
};

const createLinksList = (node, lang) => {
  const routesKeys = Object.keys(node);

  return routesKeys.reduce((acc, url) => {
    let { name } = node[url];
    const { withoutLinks } = node[url];
    const isEng = lang === langList.ENG;

    if (isEng) {
      const engName = node[url].nameEng;

      if (!engName) {
        return acc;
      }

      name = node[url].nameEng;
    }

    const link = {
      url: url === ABOUT_PATH ? `${ABOUT_PATH}/info` : url,
      name,
    };

    if (!withoutLinks) {
      acc.push(link);
    }

    return acc;
  }, []);
};

const getLinks = (lang, pathname) => {
  const node = routes[ROOT_PATH].links;
  const headerLinks = createLinksList(node, lang);
  let navBarLinks = [];
  let navListLinks = [];
  const slug = findKeyForNavList(pathname);

  if (slug !== ROOT_PATH && (node[slug] && node[slug].links && !node[slug].withoutLinks)) {
    const secondNode = node[slug].links;
    navBarLinks = createLinksList(secondNode, lang);

    const slugThird = getThirdSlug(pathname);
    const links = Object.keys(secondNode);
    const thirdKey = links.find((item) => slugThird === item);

    if (secondNode[thirdKey] && secondNode[thirdKey].links) {
      if (secondNode[thirdKey].links && !secondNode[thirdKey].withoutLinks) {
        if (lang === langList.ENG && secondNode[thirdKey].withoutLinksEng) {
          navListLinks = [];
        } else {
          navListLinks = createLinksList(secondNode[thirdKey].links, lang);
        }
      }
    }
  }

  return {
    headerLinks,
    navBarLinks,
    navListLinks,
  };
};

export default getLinks;
