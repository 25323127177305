export const actionTypes = {
  SEND: 'SEND',
  SEND_REQUEST: 'SEND_REQUEST',
  SEND_SUCCESS: 'SEND_SUCCESS',
  SEND_ERROR: 'SEND_ERROR',
};

export const send = (data) => ({
  type: actionTypes.SEND,
  data,
});

export const sendRequest = () => ({
  type: actionTypes.SEND_REQUEST,
});

export const sendSuccess = () => ({
  type: actionTypes.SEND_SUCCESS,
});

export const sendError = (error) => ({
  type: actionTypes.SEND_ERROR,
  error,
});
