import React from 'react';
import App from 'next/app';
import styled from 'styled-components';
import Router from 'next/router';

import {
  LoadingBar, BaseStyles, RespContainer, media,
} from '@pik/pik-ui';
import ErrorPage from '@pik/pik-error-page';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { redux, parseUserAgent } from '@pik/pik-utils';

import Head from '../components/Head';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Title from '../components/Title';
import ResponsiveContainer from '../components/ResponsiveContainer';
import NavList from '../components/NavList';
import ContactModal from '../components/ContactModal';
import Chat from '../components/Chat';

import { initApi } from '../common/api';
import apiServices from '../common/apiServices';
import langList from '../common/constants/langList';
import getLinks from '../common/utils/getLinks';

import { wrapper } from '../services/store';

import { apiGetInitData } from '../services/app/api';
import { initApp } from '../services/app/actions';

import routes from '../server/routes';

const { withReduxSaga } = redux;
require('dayjs/locale/ru');

dayjs.extend(customParseFormat);

export const Wrapper = styled.div`
  min-height: calc(100vh - 212px);
  position: relative;
  margin-bottom: 170px;
  
  @media ${media.max768} {
    padding: 0 16px;
  }
`;

export const DefaultContainer = styled.div`
  position: relative;
  display: flex;
`;

export const ComponentWrapper = styled.div`
  width: 100%;
`;

const getRedirect = ({
  ctx, redirect, redirectEng, lang,
}) => {
  let path = redirect;

  if (lang === langList.ENG) {
    if (redirectEng) {
      path = redirectEng;
    }
  }

  if (ctx.res) {
    ctx.res.writeHead(302, { Location: path });
    ctx.res.end();
  } else {
    Router.push(path);
  }
};

function findRoute(node, path) {
  if (!node) {
    return null;
  }

  const routesKeys = Object.keys(node);

  for (const item of routesKeys) {
    if (item === path) {
      return node[item];
    }

    const child = findRoute(node[item].links, path);
    if (child) {
      return child;
    }
  }
}

class PikGroup extends App {
  static async getInitialProps({ ctx, res }) {
    let { redirect, redirectEng } = ctx.query;
    const customProps = {};

    if (ctx.res) {
      initApi(apiServices);
      const userAgent = parseUserAgent(ctx.req.headers['user-agent'] || '');

      ctx.store.dispatch(initApp({
        pathname: ctx.asPath,
        host: ctx.req.headers.host,
        query: ctx.query,
        userAgent,
      }));
    }

    const { appService: { lang } } = ctx.store.getState();

    if (lang === langList.ENG) {
      dayjs.locale('en');
    } else {
      dayjs.locale('ru');
    }

    if (!res) {
      const currentRout = findRoute(routes, ctx.pathname);

      if (currentRout) {
        redirect = currentRout.redirect;
        redirectEng = currentRout.redirectEng;
      }
    }

    if (redirect || redirectEng) {
      getRedirect({
        ctx,
        redirect,
        redirectEng,
        lang,
      });
    }

    let pageData = {};

    try {
      const data = await apiGetInitData(ctx.pathname, lang);
      pageData = data;
    } catch (error) {
      return { statusCode: 404 };
    }

    return {
      pageData: {
        ...pageData,
        ...customProps,
      },
      lang,
    };
  }

  state = {
    loading: false,
  };

  static getDerivedStateFromProps(nextProps) {
    const { lang } = nextProps;

    if (lang === langList.ENG) {
      dayjs.locale('en');
    } else {
      dayjs.locale('ru');
    }

    initApi(apiServices);

    return {};
  }

  componentDidMount() {
    Router.onRouteChangeStart = () => this.setState({ loading: true });
    Router.onRouteChangeComplete = () => this.setState({ loading: false });
  }

  renderComponent = (navListLinks) => {
    const {
      Component, pageData, pageProps,
    } = this.props;
    const withNavList = navListLinks && !!navListLinks.length;

    let data = {
      ...pageData,
    };

    if (pageProps && pageProps.pageData) {
      data = {
        ...data,
        ...pageProps.pageData,
      };
    }

    if (pageProps && pageProps.withoutPageContainer) {
      return <Component pageData={data} />;
    }

    return (
      <RespContainer>
        {pageData.title && <Title>{pageData.title}</Title>}
        <DefaultContainer>
          {withNavList && <NavList links={navListLinks} />}
          <ComponentWrapper>
            <Component pageData={data} />
          </ComponentWrapper>
        </DefaultContainer>
      </RespContainer>
    );
  }

  render() {
    const { loading } = this.state;
    const {
      lang, router, pageProps, pageData,
    } = this.props;

    if (!pageData || this.props.statusCode || (pageProps && pageProps.error)) {
      return <ErrorPage statusCode={this.props.statusCode} />;
    }

    const title = pageData.title || 'Pik group';

    const {
      headerLinks,
      navBarLinks,
      navListLinks,
    } = getLinks(lang, router.pathname);

    const canonicalUrl = router.pathname;
    const queryLang = router.query.lang || null;

    return (
      <>
        <BaseStyles />
        <Head
          data={{
            title,
          }}
          canonicalUrl={canonicalUrl}
          queryLang={queryLang}
        />
        <Header
          links={headerLinks}
          navBarLinks={navBarLinks}
          navListLinks={navListLinks}
          pathname={router.pathname}
        />
        <LoadingBar loading={loading} />
        <Wrapper id="AppWrapper">
          {this.renderComponent(navListLinks)}
        </Wrapper>
        <div className="blackout" />
        <Footer data={pageData.footer} />
        <Chat />
        <ContactModal data={pageData.contactForm} />
        <ResponsiveContainer />
      </>
    );
  }
}

export default wrapper.withRedux(withReduxSaga(PikGroup));
