export default {
  v: '5.1.19',
  fr: 60,
  ip: 0,
  op: 55,
  w: 76,
  h: 72,
  nm: 'succcceeessss',
  ddd: 0,
  assets: [],
  layers: [{
    ddd: 0,
    ind: 1,
    ty: 4,
    nm: 'Check',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [38, 36, 0], ix: 2 }, a: { a: 0, k: [38, 36, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [0, 0]], o: [[0, 0], [0, 0], [0, 0]], v: [[-27.907, 0], [-10.465, 17.442], [27.907, -17.442]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0.149019607843, 0.2, 0.301960784314, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 4, ix: 5 }, lc: 1, lj: 1, ml: 4, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [45.977, 29.512], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Group 1',
      np: 2,
      cix: 2,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm',
      s: {
        a: 1,
        k: [{
          i: { x: [0.342], y: [1.005] }, o: { x: [0.188], y: [0.401] }, n: ['0p342_1p005_0p188_0p401'], t: 0, s: [0], e: [100],
        }, { t: 55 }],
        ix: 1,
      },
      e: { a: 0, k: 0, ix: 2 },
      o: { a: 0, k: 0, ix: 3 },
      m: 1,
      ix: 2,
      nm: 'Trim Paths 1',
      mn: 'ADBE Vector Filter - Trim',
      hd: false,
    }],
    ip: 0,
    op: 55,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 2,
    ty: 4,
    nm: 'round',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [38, 36, 0], ix: 2 }, a: { a: 0, k: [38, 36, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0.515, -2.703], [5.136, -5.137], [13.623, 13.623], [-1.147, 10.186], [-5.812, 5.812], [-13.653, -10.079]], o: [[0.128, 2.74], [-1.263, 6.636], [-13.623, 13.623], [-7.811, -7.811], [0.853, -7.578], [12.364, -12.364], [0, 0]], v: [[35.264, -1.699], [34.684, 6.497], [25.085, 24.609], [-24.248, 24.609], [-34.245, -3.982], [-24.248, -24.725], [21.105, -28.152]], c: false,
          },
          ix: 2,
        },
        nm: 'Path 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'tm',
        s: {
          a: 1,
          k: [{
            i: { x: [0.212], y: [1.009] }, o: { x: [0.383], y: [-0.017] }, n: ['0p212_1p009_0p383_-0p017'], t: 0, s: [0], e: [100],
          }, { t: 55 }],
          ix: 1,
        },
        e: { a: 0, k: 0, ix: 2 },
        o: { a: 0, k: 0, ix: 3 },
        m: 1,
        ix: 2,
        nm: 'Trim Paths 1',
        mn: 'ADBE Vector Filter - Trim',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0.83137254902, 0.839215686275, 0.858823529412, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 2, ix: 5 }, lc: 1, lj: 1, ml: 4, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [35.582, 36.058], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 50, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform',
      }],
      nm: 'Group 2',
      np: 3,
      cix: 2,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 0,
    op: 55,
    st: 0,
    bm: 0,
  }],
  markers: [],
};
