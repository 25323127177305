import { api as apiConstructor } from '@pik/pik-utils';

const api = {};

export const initApi = (config) => {
  const newApi = apiConstructor(config, config.token);
  Object.assign(api, newApi);
};

export default api;
