import React from 'react';

export default () => (
  <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M18 9H3" stroke="#4D4D4D" strokeWidth="2" />
      <path fill="none" d="M-2-3h24v24H-2z" />
      <path stroke="#4D4D4D" strokeWidth="2" d="M10 1L2 9l8 8" />
    </g>
  </svg>
);
