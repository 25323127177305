import {
  takeEvery, put,
} from 'redux-saga/effects';

import {
  actionTypes,
  updateUserAgent,
  changeLang,
} from './actions';

import {
  changeScreenType,
} from '../appClient/actions';

import hosts from '../../common/constants/hosts';

const getLang = (host) => {
  if (process.env.NODE_ENV === 'production') {
    const keys = Object.keys(hosts);
    return keys.find((key) => hosts[key] === host);
  }

  return process.env.LANG;
};

function* initAppSaga({ options }) {
  try {
    const { userAgent, host } = options;
    yield put(changeLang(getLang(host)));
    console.log(host);

    if (userAgent) {
      const screenType = userAgent.isMobile ? 'mobile' : 'desktop';
      yield put(updateUserAgent(userAgent));
      yield put(changeScreenType(screenType));
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* watchSagas() {
  yield takeEvery(actionTypes.INIT_APP, initAppSaga);
}
