export const actionTypes = {
  SHOW_CONTACT_MODAL: 'SHOW_CONTACT_MODAL',
  HIDE_CONTACT_MODAL: 'HIDE_CONTACT_MODAL',
  SEND_CONTACT: 'SEND_CONTACT',
  SEND_CONTACT_REQUEST: 'SEND_CONTACT_REQUEST',
  SEND_CONTACT_SUCCESS: 'SEND_CONTACT_SUCCESS',
  SEND_CONTACT_ERROR: 'SEND_CONTACT_ERROR',
  SEND_CHECK_PHONE: 'SEND_CHECK_PHONE',
  UPDATE_SAVED_PHONE: 'UPDATE_SAVED_PHONE',
  CHECK_SMS_CODE: 'CHECK_SMS_CODE',
  CHECK_SMS_CODE_ERROR: 'CHECK_SMS_CODE_ERROR',
  BACK_TO_FORM: 'BACK_TO_FORM',
};

export const showContactModal = () => ({
  type: actionTypes.SHOW_CONTACT_MODAL,
});

export const hideContactModal = () => ({
  type: actionTypes.HIDE_CONTACT_MODAL,
});

export const sendContact = (values) => ({
  type: actionTypes.SEND_CONTACT,
  values,
});

export const sendContactRequest = () => ({
  type: actionTypes.SEND_CONTACT_REQUEST,
});

export const sendContactSuccess = () => ({
  type: actionTypes.SEND_CONTACT_SUCCESS,
});

export const sendContactError = (error, somethingWrong) => ({
  type: actionTypes.SEND_CONTACT_ERROR,
  error,
  somethingWrong,
});

export const sendCheckPhone = (phone) => ({
  type: actionTypes.SEND_CHECK_PHONE,
  phone,
});

export const backToForm = () => ({
  type: actionTypes.BACK_TO_FORM,
});

export const updateSavedPhone = (phone) => ({
  type: actionTypes.UPDATE_SAVED_PHONE, phone,
});

export const checkSmsCode = (code, data) => ({
  type: actionTypes.CHECK_SMS_CODE, code, data,
});

export const checkSmsCodeError = (error) => ({
  type: actionTypes.CHECK_SMS_CODE_ERROR, error,
});
